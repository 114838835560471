.soon {
  display: flex;
  align-items: center;

  .icon {
    color: #a6bfd9;
    margin-right: 10px;
  }

  .description {
    color: var(--text-text-400, #335474);
    font-size: 13px;
    font-family: Roboto;
    line-height: 120%;
  }
}
