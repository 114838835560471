.item {
  display: flex;

  .nav {
    width: 350px;
    margin-right: 20px;
    flex-grow: 0;
  }

  .sections {
    margin-top: -20px;
    flex-grow: 2;
    width: 700px;
  }
}

.footer {
  margin-left: 370px;
  margin-top: 20px;
}
