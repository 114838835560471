.invite {
  .names {
    border-radius: 8px;
    border: 1px solid var(--grey-300, #e9eff6);
    background: var(--white, #fff);
    padding: 17px 15px;
    color: var(--text-text-main, #011e3d);
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .example {
    border-radius: 8px;
    background: var(--background-color, #f5f8fa);
    padding: 16px 24px;

    .header {
      color: var(--text-text-main, #011e3d);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 9px;
    }

    .description {
      color: var(--text-text-main, #011e3d);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
    }

    .link {
      color: var(--primary-500, #2499ef);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-decoration-line: underline;
    }
  }
}
