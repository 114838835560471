.input {
  position: relative;

  &[data-error='error'] {
    .icon {
      color: red;
      opacity: 1;
    }
  }

  .icon {
    font-size: 14px;
    position: absolute;
    top: 0;
    left: 5px;
    z-index: 1000000;
    opacity: 0.5;
    font-weight: 700;
  }
}
