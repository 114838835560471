.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .empty {
    font-size: 0.8em;
    font-weight: 500;
    opacity: 0.5;
  }
}
