.exclude {
  border-radius: 8px;
  border: 1px solid var(--other-error, #ff316f);
  background: rgba(255, 49, 111, 0.15);
  padding: 16px;
  display: flex;

  .icon {
    color: #ff316f;
  }

  .description {
    color: var(--text-text-main, #011e3d);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    margin-left: 12px;
  }
}
