.select {
  padding: 20px 24px;

  .description {
    color: var(--Text-Text-Main, #011e3d);
    font-size: 14px;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    margin-bottom: 16px;
  }

  .sections {
    .section {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      cursor: pointer;

      .radio {
        margin-right: 8px;
      }

      .color {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 6px;
      }

      .title {
        color: var(--Text-Text-Main, #011e3d);
        font-size: 14px;
      }
    }
  }
}
