.layout {
  .body {
    display: flex;

    .sidebar {
      flex-grow: 0;
    }

    .content {
      flex-grow: 1;
      margin-left: 300px;
    }
  }
}
