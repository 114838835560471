.actual {
  .extra {
    .header {
      display: flex;
      align-items: center;

      .info {
        position: relative;
        top: 1px;
        color: #45abf6;
        margin-right: 4px;

        svg {
          font-size: 16px;

          color: #45abf6;
        }
      }
    }

    .request {
      margin-top: 16px;

      .input {
        position: relative;
      }
      .icon {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
  }
}
