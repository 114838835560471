.modal {
  .description {
    font-size: 14px;
    line-height: 140%;
    color: #011e3d;
    margin-bottom: 8px;
  }

  .link {
    margin-bottom: 20px;
    display: block;

    a {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-decoration: underline;
      color: #2499ef;
    }
  }

  .calendar {
    display: flex;
    align-items: center;

    .select {
      flex-grow: 2;
      font-size: 14px;
    }

    .button {
      flex-grow: 0;
      margin-left: 16px;
    }
  }
}
