.table {
  width: 100%;

  table {
    width: 100%;

    thead {
      th {
        padding: 12px 16px;
        background: #f1f6f9;
        text-align: left;
        font-weight: 500;
        font-size: 14px;
        color: #738499;

        &:first-of-type {
          border-radius: 8px 0 0px 0px;
        }

        &:last-of-type {
          border-radius: 0 8px 0 0;
        }
      }
    }

    tbody {
      td {
        border-width: 1px 0px;
        border-style: solid;
        border-color: #e9eff6;
        padding: 24px 16px;
        font-size: 14px;
        line-height: 16px;
        color: #011e3d;

        &:last-of-type {
          width: 200px;
        }
      }
    }
  }
}
