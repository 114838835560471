.tree {
  .search {
    margin-bottom: 16px;
  }

  .empty {
    margin-top: 8px;
    font-size: 13px;
  }
}
