.header {
  margin-bottom: 32px;

  &[data-small='small'] {
    margin-bottom: 16px;
  }

  .title {
    color: var(--text-text-main, #011e3d);
    font-size: 16px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 4px;
  }

  .description {
    color: var(--text-text-400, #335474);
    font-size: 14px;
    font-family: Roboto;
    line-height: 140%;
    border-bottom: 1px solid var(--grey-400, #dce5f0);
    padding-bottom: 10px;

    p {
      width: 80%;
    }
  }
}
