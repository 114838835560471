.button {
  &[data-type='add'] {
    color: #2499ef;
    height: 32px;
    flex-grow: 2;

    svg {
      color: #2499ef;
    }
  }

  &[data-type='remove'] {
    margin-left: 10px;
    color: #e53935;
    height: 32px;
    flex-grow: 0;

    svg {
      color: #e53935;
    }
  }
}
