.selector {
  &[data-error='error'] {
    .container {
      border-color: red;
    }
  }

  .container {
    border-radius: 8px;
    border: 1px solid var(--grey-400, #dce5f0);
    padding: 15px 15px 11px 15px;
    min-height: 56px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    display: flex;
    align-items: center;

    .tags {
      flex-grow: 2;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .items {
        flex-grow: 0;
      }

      .add {
        flex-grow: 2;
        margin-right: 8px;
      }
    }

    .icon {
      flex-grow: 0;

      svg {
        font-size: 12px;
        color: #335474;
      }
    }
  }
}
