.modal {
  display: flex;

  .dict,
  .selected {
    padding: 24px;

    .header {
      color: var(--text-text-main, #011e3d);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 16px;
    }
  }

  .dict {
    width: 50%;
    border-right: 1px solid #e9eff6;
  }

  .selected {
    width: 50%;
  }
}

.footer {
  border-top: 1px solid var(--grey-300, #e9eff6);
}
