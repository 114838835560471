.more {
  .add {
    display: flex;
    align-items: center;

    .input {
      flex-grow: 2;
    }

    .buttons {
      flex-grow: 0;
    }
  }
}
