.form {
  width: 440px;

  .header {
    color: var(--text-text-main, #011e3d);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
  }

  .note {
    margin-top: 6px;
    color: var(--text-text-muted-300, #738499);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .fields {
    margin-bottom: 16px;
  }
}
