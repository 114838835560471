.input {
  border-radius: 4px;
  // border: 1px solid var(--primary-400, #45abf6);
  border: none;
  background: var(--White, #fff);
  width: 100%;
  // padding: 3px 7px;
  padding: 0;
  color: var(--text-text-400, #335474);
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  outline: none;
  width: 100%;
  position: relative;
  bottom: 1px;

  &[data-error='error'] {
    color: red;
  }

  &[data-padding='left'] {
    padding-left: 20px;
  }
}
