.chip {
  border-radius: 4px;
  opacity: 0.8999999761581421;
  background: rgba(39, 206, 136, 0.15);
  padding: 2px 6px 4px 6px;

  span {
    color: var(--other-complete, #27ce88);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
