.options {
  position: absolute;
  width: 100%;
  border: 1px solid var(--grey-400, #dce5f0);
  background: var(--White, #fff);
  border-radius: 8px;
  top: 60px;
  z-index: 1000000;
  box-shadow: 0px 4px 10px 2px rgba(19, 80, 124, 0.08),
    4px 0px 10px 2px rgba(19, 80, 124, 0.08);

  &[data-empty='empty'] {
    .tooltip {
      border-radius: 8px !important;
    }
  }

  &[data-create='false'] {
    .item {
      &:last-of-type {
        border-radius: 0 0 8px 8px;
      }
    }
  }

  .items {
    max-height: 250px;
    overflow-y: hidden;
  }

  .item {
    padding: 13px 15px;
    border-bottom: 1px solid var(--grey-400, #dce5f0);
    cursor: pointer;
    background: #fff;
    transition: 0.3s background;
    display: flex;
    align-items: center;
    height: 53px;

    &[data-active='active'] {
      background: var(--primary-100, #e5f3fd);

      .button {
        display: block;
      }
    }

    &:hover {
      background: var(--primary-100, #e5f3fd);

      .button {
        display: block;
      }
    }

    &.tooltip {
      color: var(--text-text-400, #335474);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 8px 8px 0 0;

      &:last-of-type {
        border-radius: 8px;
      }

      &:hover {
        background: #fff;
        cursor: default;
      }
    }

    .tag {
      flex-grow: 2;
    }

    .button {
      flex-grow: 0;
      display: none;
      padding: 2px 4px 0 4px;

      &:active {
        position: relative;
        top: 1px;
      }

      &:hover {
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.1);
      }

      svg {
        color: #738499;
        font-size: 20px;
      }
    }
  }

  .create {
    display: flex;
    align-items: center;
    border-radius: 0 0 8px 8px;

    .label {
      margin-right: 8px;
      color: var(--text-text-main, #011e3d);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
