.residency {
  .exclude {
    display: block;
  }

  .status {
    display: flex;
    align-items: center;

    .inviteAgain {
      margin-left: 20px;
    }

    button {
      white-space: nowrap;
    }
  }
}
