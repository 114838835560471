.select {
  position: relative;
  cursor: pointer;

  &[data-restricted='restricted'] {
    .input {
      .icon {
        svg {
          color: #2499ef;
        }
      }
    }
  }

  &[data-expanded='expanded'] {
    .options {
      display: block;
    }
  }

  .input {
    background: #ffffff;
    border: 1px solid #dce5f0;
    border-radius: 8px;
    font-size: 13px;
    color: #738499;
    display: flex;
    align-items: center;
    padding: 15px;
    margin-bottom: 10px;

    &:hover {
      border: 1px solid #45abf6;
      color: #011e3d;
    }

    .icon {
      margin-right: 10px;

      svg {
        font-size: 1.4em;
      }
    }
  }

  .options {
    // position: absolute;
    width: 100%;
    // top: 107%;
    left: 0;
    box-shadow: 4px 0px 10px 2px rgba(19, 80, 124, 0.06),
      0px 4px 10px 2px rgba(19, 80, 124, 0.06);
    z-index: 100;
    display: none;

    .option {
      background: #ffffff;
      border: 1px solid #dce5f0;
      border-radius: 8px;
      font-size: 13px;
      color: #738499;
      display: flex;
      align-items: center;
      padding: 15px;

      &[data-restricted='restricted'] {
        .icon {
          svg {
            color: #45abf6;
          }
        }
      }

      &:first-of-type {
        border-radius: 8px 8px 0 0;
        border-bottom: none;
      }

      &:last-of-type {
        border-radius: 0 0 8px 8px;
      }

      &:hover {
        outline: 1px solid #45abf6;
        color: #011e3d;
      }

      .icon {
        margin-right: 10px;

        svg {
          font-size: 1.4em;
        }
      }

      .details {
        .title {
          font-size: 13px;
          color: #011e3d;
          margin-bottom: 4px;
        }

        .description {
          font-size: 12px;
          line-height: 14px;
          color: #738499;
        }
      }
    }
  }
}
