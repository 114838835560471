.modal {
  display: flex;

  .tabs {
    width: 284px;
    border-right: 1px solid var(--grey-400, #dce5f0);
  }

  .content {
    padding: 24px;
    width: 850px;
  }
}

.footer {
  border-top: 1px solid #f1f6f9;
  padding: 12px 24px;
}
