.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid var(--grey-300, #e9eff6);
  height: 61px;

  &:hover {
    .buttons {
      visibility: visible;
    }
  }

  &[data-checkbox='checkbox'] {
    .title {
      width: 380px;
    }
  }

  &[data-padded='padded'] {
    padding-left: 56px;

    .title {
      width: 350px;
    }
  }

  &[data-new='new'] {
    .title {
      flex-grow: 0;
      margin-right: 20px;
    }

    .new {
      display: flex;
      flex-grow: 2;
    }
  }

  .checkbox {
    flex-grow: 0;
    margin-right: 16px;
    flex-grow: 0;
  }

  .title {
    flex-grow: 2;
    color: var(--text-text-main, #011e3d);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    // margin-right: 20px;
    // width: 400px;
    flex-grow: 2;
  }

  .count {
    flex-grow: 0;
    font-weight: 600;
    font-size: 0.9em;
    opacity: 0.7;
    color: var(--primary-500, #2499ef);
    margin-left: 10px;
    position: relative;
    top: 1px;
  }

  .buttons {
    flex-grow: 0;
    visibility: hidden;
    width: 60px;
    text-align: right;

    svg {
      font-size: 1em;
    }
  }
}
