.event {
  .filters {
    margin-bottom: 24px;

    .header {
      color: var(--text-text-main, #011e3d);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      margin-bottom: 8px;
    }

    .selects {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .dropdowns {
      display: flex;
    }

    .payment,
    .registration {
      width: 202px;
      margin-right: 8px;
    }

    .payment {
      flex-grow: 0;
    }

    .registration {
      flex-grow: 2;
    }

    .button {
      flex-grow: 0;
    }
  }

  .select {
    color: var(--text-text-main, #011e3d);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 5px 5px 6px 5px;
    border-radius: 4px;
    border: 1px solid var(--grey-400, #dce5f0);
  }

  .table {
    width: 100%;

    thead {
      th {
        padding: 12px 16px;
        background: #f1f6f9;
        text-align: left;
        color: var(--text-text-muted-300, #738499);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        &:first-of-type {
          border-radius: 8px 0 0px 0px;
        }

        &:last-of-type {
          border-radius: 0 8px 0 0;
        }
      }
    }

    tbody {
      td {
        border-width: 1px 0px;
        border-style: solid;
        border-color: #e9eff6;
        padding: 16px;
        color: var(--text-text-main, #011e3d);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        vertical-align: middle;

        a {
          color: var(--primary-500, #2499ef);
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        &:last-of-type {
          width: 200px;
        }
      }
    }
  }
}
