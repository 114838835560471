.dict {
  .remove {
    margin-bottom: 5px;
  }

  .list {
    margin-bottom: 16px;
  }

  .footer {
    .add {
      display: flex;
      align-items: center;

      .input {
        flex-grow: 2;
      }

      .buttons {
        flex-grow: 0;
      }
    }
  }
}
