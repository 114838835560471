.dropdown {
  position: relative;

  .options {
    position: absolute;
    top: 110%;
    left: 0;
    border: 1px solid #ccddf0;
    background: #fff;
    box-shadow: 4px 0px 10px 2px rgba(19, 80, 124, 0.06),
      0px 4px 10px 2px rgba(19, 80, 124, 0.06);
    border-radius: 8px;
    width: 100%;
    z-index: 100;

    .option {
      display: flex;
      padding: 10.5px 8px;
      border-bottom: 1px solid #ccddf0;
      cursor: pointer;

      &:hover {
        background: #e5f3fd;
      }

      &:last-of-type {
        border-bottom: none;
      }

      .marker {
        width: 16px;
        height: 16px;
        border: 1px solid #2499ef;
        border-radius: 50%;
        padding: 1px;
        margin-right: 8px;

        &[data-active='active'] {
          .filler {
            display: block;
          }
        }

        .filler {
          width: 12px;
          height: 12px;
          background: #2499ef;
          border-radius: 50%;
          display: none;
        }
      }

      .title {
        font-family: 'Roboto';
        font-style: normal;
        font-size: 13px;
        color: #011e3d;
      }
    }
  }
}
