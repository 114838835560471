.card {
  background: #ffffff;
  border: 1px solid #a7beda;
  border-radius: 8px;
  display: inline-block;
  width: 264px;
  height: 270px;
  margin-right: 16px;
  margin-bottom: 16px;
  padding: 16px;
  cursor: pointer;
  position: relative;

  .checkbox {
    text-align: right;
    cursor: default !important;

    input {
      border: 1px solid #2499ef !important;
    }
  }

  .title {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    color: #011e3d;
    margin-bottom: 16px;
  }

  .recurring {
    color: var(--grey-900, #647283);
    font-size: 12px;
    font-family: Roboto;
    font-style: italic;
    margin-bottom: 8px;
    // height: 12px;
  }

  .date {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    color: #647283;
    margin-bottom: 4px;
  }

  .city {
    font-family: 'Roboto';
    font-size: 14px;
    display: flex;
    color: #7d8fa4;
    margin-bottom: 8px;
  }

  .format {
    font-family: 'Roboto';
    font-size: 14px;
    color: #7d8fa4;
    margin-bottom: 16px;
  }

  .type {
    background: #e5f3fd;
    border-radius: 4px;
    padding: 8px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    color: #45abf6;
    margin-bottom: 16px;
  }

  .link {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    color: #647283;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    &:hover {
      .label {
        color: #45abf6;
      }
    }

    .icon {
      margin-right: 5px;
      position: relative;
      top: 1px;
      padding: 4px;
      background: #e5f3fd;
      border-radius: 4px;

      svg {
        font-size: 1em;
        color: 2499ef;
      }
    }

    .label {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-decoration: underline;
      color: #2499ef;
    }
  }

  .restricted {
    text-align: right;
    position: absolute;
    right: 16px;
    bottom: 16px;
  }
}
