.dict {
  margin-top: 16px;

  .item {
    border-top: 1px solid var(--grey-300, #e9eff6);
    background: var(--white, #fff);
    color: var(--text-text-main, #011e3d);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 15px 0;
    display: flex;
    align-items: center;

    .color {
      width: 8px;
      height: 8px;
      border-radius: 8px;
      margin-right: 3px;
    }

    &:last-of-type {
      border-bottom: 1px solid var(--grey-300, #e9eff6);
    }
  }

  .button {
    margin-top: 16px;
    margin-bottom: 16px;

    button {
      padding: 0;
      border: none;
      background: transparent;
      color: var(--primary-500, #2499ef);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;

      &:active {
        position: relative;
        top: 1px;
      }

      &:hover {
        color: #45abf6;
      }
    }
  }
}
