.header {
  color: var(--text-text-main, #011e3d);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  padding-bottom: 8px;
  border-bottom: 1px solid var(--grey-400, #dce5f0);
  margin-bottom: 16px;
}
