.exclude {
  width: 500px;
  margin-top: 16px;

  &[data-restore='restore'] {
    .button {
      color: var(--other-error, #2499ef);
    }
  }

  .button {
    color: var(--other-error, #ff316f);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    .icon {
      position: relative;
      top: 1px;
      margin-right: 8px;

      svg {
        font-size: 16px;
      }
    }

    &:active {
      position: relative;
      top: 1px;
    }
  }

  .description {
    color: var(--text-text-muted-300, #738499);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
  }
}
