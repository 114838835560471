.add {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 16px;
  position: relative;

  .label,
  .info,
  .plus {
    color: #45abf6;

    svg {
      color: #45abf6;
    }
  }

  .info,
  .plus {
    position: relative;
    top: 1px;

    svg {
      font-size: 16px;
    }
  }

  .info {
    margin-right: 4px;
  }

  .label {
    color: var(--primary-400, #45abf6);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
