.event {
  .section {
    padding-bottom: 32px;
    border-bottom: 1px solid #dce5f0;
    margin-bottom: 32px;

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
      margin-bottom: 0;
    }
  }
  .header {
    .title {
      font-weight: 500;
      font-size: 14px;
      color: #011e3d;
      margin-bottom: 8px;
    }

    .description {
      font-size: 14px;
      color: #335474;
      margin-bottom: 24px;
      line-height: 140%;
    }
  }

  .form {
    .title {
      margin-bottom: 16px;
    }

    .location {
      margin-bottom: 16px;
    }

    .dates {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      section {
        flex-grow: 2;

        &:first-of-type {
          margin-right: 8px;
        }

        &:last-of-type {
          margin-left: 8px;
        }
      }
    }

    .editButton {
      margin-top: 16px;
      button,
      svg {
        color: #1e88e5;
      }
    }

    .city {
      margin-bottom: 16px;
    }

    .format {
      margin-bottom: 16px;
    }

    .type {
      margin-bottom: 16px;

      .input {
        margin-bottom: 6px;
      }

      .description {
        font-size: 12px;
        color: #738499;
      }
    }

    .restricted {
      margin-bottom: 16px;
    }

    .conditions {
      margin-bottom: 16px;
    }

    .link,
    .conditions {
      .input {
        margin-bottom: 6px;
      }

      .description {
        font-size: 12px;
        color: #738499;
      }
    }
  }
}
