@import '@styles';

.modal {
  display: flex;

  .tabs {
    border-right: 1px solid #ddd;
    width: 180px;
  }

  .content {
    padding: 24px;
    width: 100%;

    @include mediaHeight(572px, 400px, 300px);
  }
}
