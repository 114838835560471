.tree {
  display: flex;

  .sections {
    width: 45%;
    margin-right: 3%;

    .section {
      display: flex;
      align-items: center;
      border: 1px solid #eee;
      border-bottom: none;
      padding: 15px;
      cursor: pointer;
      background: #fff;
      transition: 0.3s backgdound;

      &:hover {
        background: #eee;
      }

      &:last-of-type {
        border-bottom: 1px solid #eee;
      }

      &[data-active='active'] {
        background: #2499ef;
        color: #fff;
      }

      .color {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        flex-grow: 0;
        margin-right: 10px;
      }

      .title {
        flex-grow: 2;
        font-size: 0.9em;
        font-weight: 500;
        position: relative;
        top: 2px;
      }

      .count {
        flex-grow: 0;
        font-size: 0.8em;
        opacity: 0.5;
        font-weight: 500;
        position: relative;
        top: 2px;
      }
    }
  }

  .items {
    width: 40%;
    max-height: 500px;
    overflow-y: auto;

    .empty {
      font-size: 0.9em;
      opacity: 0.5;
    }
  }
}
