.residents {
  .heading {
    margin-bottom: 40px;

    .management {
      display: flex;

      .title {
        margin-right: 5px;
      }

      .count {
        display: block;
        width: 20px;
        height: 20px;
        background: var(--other-error, #ff316f);
        border-radius: 50%;

        span {
          color: var(--white, #fff);
          font-size: 10px;
          font-family: Roboto;
          font-weight: 500;
          position: relative;
          bottom: 1px;
        }
      }
    }
  }

  .search {
    margin-bottom: 8px;
  }

  .switch {
    margin-bottom: 16px;
  }

  .tabs {
    margin-bottom: 16px;
  }
}
