.club {
  .heading {
    margin-bottom: 40px;
  }

  .title {
    color: var(--text-text-main, #011e3d);
    font-size: 18px;
    font-family: Roboto;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 16px;
    max-width: 672px;
  }

  .description {
    color: var(--text-text-400, #335474);
    font-size: 16px;
    font-family: Roboto;
    line-height: 140%;
    margin-bottom: 28px;
    max-width: 672px;
  }
}
