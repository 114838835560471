.sidebar {
  width: 300px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;

  .footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
