.field {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .label {
    color: var(--text-text-400, #335474);
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    margin-bottom: 8px;
  }
}
