.tab {
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  background: #fff;
  transition: 0.3s background;
  justify-content: space-between;
  border-bottom: 1px solid var(--grey-300, #e9eff6);
  position: relative;

  &[data-invalid='invalid'] {
    .title {
      display: flex;
      align-items: center;

      .invalid {
        margin-left: 7px;
        position: relative;
        top: 1px;
      }
    }
    // .title {
    //   color: red !important;
    // }

    // .icon {
    //   svg {
    //     color: red !important;
    //   }
    // }
    // outline: 1px solid red;
  }

  &[data-sub='sub'] {
    border-bottom: none;
    padding: 12px 16px;

    .title {
      font-weight: 400;
    }
  }

  &[data-parent='parent'] {
    &[data-expanded='expanded'] {
      border-bottom: none;
    }
  }

  &[data-borderless='borderless'] {
    border-bottom: none;
  }

  &[data-active='active'] {
    .icon {
      svg {
        color: #2499ef;
      }
    }

    .title {
      color: #2499ef;
    }
  }

  .invalid {
    // position: absolute;
    // top: 20px;
    // right: 20px;
  }

  &:hover {
    background: #f1f6f9;

    .icon {
      svg {
        color: #2499ef;
      }
    }

    .title {
      color: #2499ef;
    }
  }

  .icon {
    width: 2em;
    position: relative;
    top: 1px;
    flex-grow: 0;

    svg {
      font-size: 1.4em;
      color: #96abc4;
      transition: 0.3s color;
    }
  }

  .title {
    font-weight: 500;
    font-size: 14px;
    color: #011e3d;
    transition: 0.3s color;
    flex-grow: 2;
  }

  .count {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 20px;
    height: 20px;
    background: var(--other-error, #ff316f);
    border-radius: 50%;

    span {
      color: var(--white, #fff);
      font-size: 10px;
      font-family: Roboto;
      font-weight: 500;
      position: relative;
    }
  }

  .expand {
    color: #96abc4;
    position: relative;
    top: 1px;

    svg {
      font-size: 1.7em;
    }
  }
}

.sub {
  border-bottom: 1px solid var(--grey-300, #e9eff6);
}
