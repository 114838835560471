.add {
  padding: 24px;

  .section {
    margin-bottom: 32px;
  }
}

.footer {
  border-top: 1px solid #f1f6f9;
  padding: 12px 24px !important;
}
