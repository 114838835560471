@import '@styles';

.modal {
  display: flex;

  .tabs {
    border-right: 1px solid #ddd;
    flex-grow: 0;
    width: 300px;

    *[data-sidebar-tab='tab'] {
      border-bottom: 1px solid #eee;
    }
  }

  .content {
    padding: 24px;
    overflow-y: auto;
    flex-grow: 2;
    width: 700px;

    @include mediaHeight(572px, 400px, 300px);

    .innerTabs {
      margin-bottom: 24px;
    }

    .search {
      margin-bottom: 16px;
    }
  }
}

.otherTab {
  display: flex;
}

.count {
  background: #f44336;
  color: #fff;
  font-weight: 900;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 10px;
  margin-left: 10px;

  span {
    position: relative;
    right: 0.5px;
    top: 1px;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  border-top: 1px solid #ddd;

  .clear {
    button {
      color: #ff316f;
    }
  }

  .actions {
    .cancel {
      background: #f1f8fe;
      color: #2499ef;
      border: none;

      &:hover {
        background: #c6e7ff;
      }
    }
  }
}
