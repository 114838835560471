.personal {
  .avatar {
    margin-bottom: 16px;

    img {
      width: 80px;
      height: 80px;
      border-radius: 4px;
    }
  }

  .name,
  .details {
    margin-bottom: 16px;
    display: flex;

    section {
      width: 50%;

      &:first-of-type {
        margin-right: 8px;
      }

      &:last-of-type {
        margin-left: 8px;
      }
    }
  }
}
