.survey {
  a {
    color: #2499ef;
    // color: #000;
    font-weight: 500;
  }

  .status {
    display: flex;
    align-items: center;

    .icon {
      position: relative;
      top: 2px;
      margin-right: 5px;
    }

    .title {
      font-weight: 500;
      font-size: 0.9em;
      // color: #607d8b;
      opacity: 0.8;
      position: relative;
      top: 2px;
    }
  }

  .table {
    width: 100%;

    thead {
      th {
        padding: 18px 16px;
        background: #f1f6f9;
        text-align: left;
        font-weight: 500;
        font-size: 14px;
        color: #738499;

        &:first-of-type {
          border-radius: 8px 0 0px 0px;
        }

        &:last-of-type {
          border-radius: 0 8px 0 0;
        }
      }
    }

    tbody {
      tr {
        &:nth-of-type(even) {
          background: #f1f6f9;
        }
      }
      td {
        border-width: 1px 0px;
        border-style: solid;
        border-color: #e9eff6;
        padding: 12px 16px 18px 16px;
        font-size: 14px;
        line-height: 16px;
        color: #011e3d;
        vertical-align: center;

        &:last-of-type {
          width: 200px;
        }
      }
    }
  }

  .items {
    width: 900px;

    .item {
      display: flex;
      font-size: 13px;
      color: #515151;
      font-weight: 500;
      align-items: center;
      padding: 10px 15px;
      // border: 1px solid #eee;
      // background: #f7f7f7;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      justify-content: space-between;
      margin-bottom: 5px;
      cursor: pointer;
      transition: 0.3s box-shadow;

      &:hover {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
      }

      .id {
        width: 50px;
        flex-grow: 0;
      }

      .name {
        width: 200px;
        flex-grow: 0;
        color: #2499ef;
      }

      .phone {
        width: 150px;
        flex-grow: 0;
      }

      .email {
        width: 200px;
        flex-grow: 2;
      }

      .status {
        flex-grow: 0;
        text-align: right;
      }
    }
  }
}
