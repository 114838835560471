.radio {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #2499ef;
  padding: 1px;

  &[data-checked='checked'] {
    .cursor {
      display: block;
    }
  }

  .cursor {
    display: none;
    width: 12px;
    height: 12px;
    background: #2499ef;
    border-radius: 50%;
  }
}
