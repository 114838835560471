.plain {
  .search {
    margin-bottom: 4px;
  }

  .items {
    .empty {
      margin-top: 8px;
      font-size: 13px;
    }
  }
}
