.support {
  a {
    color: #2499ef;
  }

  .header {
    font-size: 1.1em;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .section {
    margin-bottom: 20px;

    .sectionHeader {
      font-weight: 500;
      margin-bottom: 10px;
    }

    .sectionContent {
      font-size: 0.9em;

      p {
        margin-bottom: 5px;
      }

      .telegram {
        display: flex;
        align-items: center;
      }
    }
  }
}
