.progress {
  .filler {
    height: 0.3vh;
    background-color: #1e88e5;
    width: 0%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;

    &[data-active='active'] {
      animation-duration: 0.5s;
      animation-name: loading;
    }
  }
}

@keyframes loading {
  0% {
    width: 0%;
  }

  50% {
    width: 50%;
  }

  100% {
    width: 100%;
  }
}
