.button {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 4px;
  background: var(--primary-100, #e5f3fd);
  color: var(--primary-500, #2499ef);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 9px 16px 11px 8px;
  cursor: pointer;

  &:hover {
    background: var(--primary-200, #c6e7ff);
  }

  .icon {
    color: #2499ef;
    position: relative;
    top: 2px;
    margin-right: 5px;

    svg {
      font-size: 18px;
    }
  }
}
