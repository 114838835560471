.settings {
  width: 248px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 10px 2px rgba(19, 80, 124, 0.08),
    4px 0px 10px 2px rgba(19, 80, 124, 0.08);
  position: absolute;
  z-index: 100000000000;
  padding: 16px 8px;

  .input {
    margin-bottom: 8px;

    &[data-invalid='invalid'] {
      input {
        border-color: red;
      }
    }

    input {
      border-radius: 8px;
      border: 1px solid var(--primary-400, #45abf6);
      background: var(--White, #fff);
      width: 100%;
      padding: 10px 16px;
      color: var(--text-text-400, #335474);
      font-family: Roboto;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 18.2px */
      outline: none;
    }
  }

  .remove {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;

    &:hover {
      background: #e5f3fd;
    }

    .icon {
      margin-right: 4px;

      svg {
        color: #96abc4;
        font-size: 18px;
      }
    }

    .label {
      color: var(--text-text-main, #011e3d);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .close {
    position: absolute;
    top: 3px;
    right: 3px;

    svg {
      font-size: 12px;
      color: #aaa;
      cursor: pointer;
    }
  }
}
