.placeholder {
  font-family: 'Roboto';
  width: 700px;
  line-height: 140%;

  .header {
    font-weight: 500;
    font-size: 18px;
    color: #011e3d;
    margin-bottom: 16px;
  }

  p {
    font-size: 16px;
    color: #335474;
    margin-bottom: 16px;
  }

  a {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-decoration-line: underline;
    color: #2499ef;
  }
}
