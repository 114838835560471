.page {
  &[data-transparent='transparent'] {
    .content {
      background: transparent;
      box-shadow: none;
      padding: 0;
    }
  }

  .content {
    margin: 16px 18px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 2px rgba(19, 80, 124, 0.06);
    border-radius: 8px;
    width: 96%;
    padding: 20px;
  }

  .footer {
    margin: 16px 18px;
  }
}
