.item {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  box-shadow: 2px 1px 2px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.15);
  }

  &:active {
    position: relative;
    top: 1px;
  }

  &[data-block='block'] {
    display: flex;
  }

  .checkbox {
    margin-right: 5px;
  }

  .title {
    font-size: 0.9em;
    font-weight: 500;
  }
}
