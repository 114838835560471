.wrapper {
  padding-top: 20px;
}

.section {
  background: #fff;
  border: 1px solid #eee;
  padding: 20px;
  border-radius: 5px;

  .header {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    margin-bottom: 20px;

    .icon {
      margin-right: 5px;

      svg {
        color: #2499ef;
      }
    }

    .index {
      opacity: 0.5;
      margin-right: 3px;
    }
  }
}
