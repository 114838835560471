.edit {
  .section {
    margin-bottom: 20px;

    .label {
      font-size: 0.9em;
      margin-bottom: 10px;
      color: #607d8b;
    }

    .title {
      & > div {
        width: 100%;
        display: block;
      }
    }
  }
}
