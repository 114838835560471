.progress {
  .titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    .from {
      color: var(--text-text-main, #011e3d);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .to {
      color: var(--text-text-muted-300, #738499);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .slider {
    border-radius: 14.047px;
    background: var(--primary-100, #e5f3fd);
    height: 7px;
    margin-bottom: 12px;

    .filled {
      border-radius: 14.047px;
      background: var(--primary-100, #2499ef);
      height: 7px;
    }
  }

  .amount {
    color: var(--text-text-muted-300, #738499);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
