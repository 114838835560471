.card {
  border-radius: 8px;
  border: 1px solid var(--text-text-200, #a7beda);
  background: var(--white, #fff);
  padding: 16px;
  width: 264px;
  cursor: pointer;
  transition: 0.3s background;
  position: relative;

  &:hover {
    background: #f1f6f9;
  }

  &[data-user='user'] {
    .header {
      .icon {
        color: var(--primary-500, #2499ef);
      }
      .name {
        color: var(--primary-500, #2499ef);
      }
    }
  }

  &[data-archive='archive'] {
    background: var(--grey-200, #f1f6f9);
  }

  .survey {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #eee;
      margin-right: 10px;
      background-size: cover;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .icon {
      color: #cfd8dc;
      margin-right: 5px;

      svg {
        font-size: 2em;
      }
    }

    .name {
      color: var(--text-text-main, #011e3d);
      font-size: 18px;
      font-family: Roboto;

      div {
        margin-bottom: 3px;
      }
    }
  }

  .contact {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .icon {
      width: 30px;

      svg {
        font-size: 24px;
        color: #96abc4;
      }
    }

    .value {
      color: var(--text-text-main, #011e3d);
      font-size: 14px;
      font-family: Roboto;

      a {
        color: var(--primary-500, #2499ef);
        font-size: 14px;
        font-family: Roboto;
        font-weight: 500;
      }
    }
  }

  .actualRequest {
    color: var(--text-text-muted-300, #738499);
    font-size: 12px;
    font-family: Roboto;
    line-height: 140%;
  }
}
