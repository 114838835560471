.tooltip {
  position: absolute;
  z-index: 100000000000000000;
  display: flex;
  align-items: center;

  .text {
    width: 217px;
    border-radius: 5px;
    background: var(--primary-500, #2499ef);
    padding: 4px 8px;

    color: var(--White, #fff);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .angleContainer {
    .angle {
      border: 6px solid transparent;
      border-left-color: #2499ef;
      width: 1px;
      height: 1px;
    }
  }
}
