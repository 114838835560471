.calendar {
  width: 100%;

  .header {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;
    color: #011e3d;
  }

  .input {
    display: block;
    width: 100%;
    margin-bottom: 16px;
  }

  .list {
    margin-bottom: 16px;
    // max-height: 500px;
    // overflow-y: auto;
  }

  .footer {
    text-align: right;
    padding-bottom: 20px;
  }
}
