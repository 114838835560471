.alert {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 16px 24px;
  width: 824px;
  font-family: 'Roboto';

  &[data-color='warning'] {
    background: rgba(255, 198, 117, 0.15);
    border: 1px solid #ffc675;
  }

  .icon {
    flex-grow: 0;
    margin-right: 12px;
  }

  .details {
    flex-grow: 2;

    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #011e3d;
      margin-bottom: 4px;
    }

    .description {
      font-size: 12px;
      line-height: 140%;
      color: #738499;
    }
  }

  .button {
    flex-grow: 0;
    margin-left: 12px;

    button {
      color: #fff !important;
    }
  }
}
