.job {
  .add {
    margin-top: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    strong {
      color: var(--primary-500, #2499ef);
      font-size: 14px;
      font-weight: 500;
    }

    i {
      position: relative;
      top: 1px;

      svg {
        color: var(--primary-500, #2499ef);
        font-size: 1em;
      }
    }
  }
}
