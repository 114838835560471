.section {
  // border: 1px solid var(--grey-300, #e9eff6);
  border-radius: 8px;
  margin-bottom: 8px;

  &[data-expanded='expanded'] {
    .header {
      border-radius: 8px 8px 0 0;
      background: var(--grey-100, #f8fafc);

      .title {
        color: var(--primary-500, #2499ef);
      }

      .expandIcon {
        color: #2499ef;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background: var(--grey-100, #f8fafc);
    padding: 14px 16px;
    border-radius: 8px;
    border: 1px solid var(--grey-300, #e9eff6);
    transition: 0.3s background;
    cursor: pointer;

    &:hover {
      background: var(--grey-300, #e9eff6);
    }

    .expandIcon {
      flex-grow: 0;
      color: #96abc4;
      margin-right: 12px;
    }

    .color {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 10px;
      position: relative;
      flex-grow: 0;
    }

    .title {
      flex-grow: 2;
      color: var(--text-text-main, #011e3d);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .items {
    border: 1px solid var(--grey-300, #e9eff6);
    border-top: 0;
    border-radius: 0 0 8px 8px;
  }
}
