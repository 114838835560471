.events {
  .search {
    margin-bottom: 8px;
  }

  .filters {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .payment {
      width: 216px;
      flex-grow: 0;
      margin-right: 8px;
    }

    .sort {
      flex-grow: 2;
    }
  }

  .list {
    .empty {
      color: var(--text-text-400, #335474);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
    }

    .event {
      border-radius: 8px;
      border: 1px solid var(--grey-400, #dce5f0);
      background: #fff;
      padding: 16px;
      margin-bottom: 8px;

      .header,
      .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .header {
        margin-bottom: 13px;
      }

      .title {
        color: var(--text-text-main, #011e3d);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .comment {
        color: var(--text-text-muted-300, #738499);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .dates {
        color: var(--grey-900, #647283);
        text-align: right;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .icon {
        &[data-status='paid'] {
          color: #27ce88;
        }
        &[data-status='unpaid'] {
          color: #ff316f;
        }
        &[data-status='free'] {
          color: #2499ef;
        }
      }
    }
  }
}
