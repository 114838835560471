.table {
  display: block;

  table {
    width: 100%;
    font-size: 12px;

    thead {
      th {
        padding: 10px 14px;
        background: #f1f6f9;
        text-align: left;
        font-weight: 500;
        color: #738499;

        &:first-of-type {
          border-radius: 8px 0 0px 0px;
        }

        &:last-of-type {
          border-radius: 0 8px 0 0;
        }
      }
    }

    tbody {
      td {
        border-width: 1px 0px;
        border-style: solid;
        border-color: #e9eff6;
        padding: 14px 14px;
        line-height: 16px;
        color: #011e3d;

        a {
          color: var(--primary-500, #2499ef);
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        &:last-of-type {
          width: 200px;
        }
      }
    }
  }
}
