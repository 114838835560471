.card {
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 2px rgba(19, 80, 124, 0.06);
  border-radius: 8px;
  display: inline-block;
  width: 296px;
  margin-bottom: 16px;
  margin-right: 16px;
  cursor: pointer;

  &:hover {
    background: lighten(#e5f3fd, 4%);
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .icon {
      flex-grow: 0;
      margin-right: 10px;
      background: #e5f3fd;
      border-radius: 4px;
      padding: 6px;

      svg {
        font-size: 1.2em;
      }
    }

    .title {
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #011e3d;
    }

    .count {
      background: #f44336;
      color: #fff;
      font-weight: 900;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 10px;
      margin-left: 10px;

      span {
        position: relative;
        right: 0.5px;
      }
    }
  }
}
