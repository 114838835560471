.item {
  display: flex;
  align-items: center;

  &[data-last='last'] {
    &::after {
      display: none;
    }

    .title {
      display: block;
      color: #2499ef;
    }

    .icon {
      svg {
        font-size: 24px;
        color: #2499ef;
      }
    }
  }

  &::after {
    content: '/';
    color: var(--grey-700, #96abc4);
    font-size: 18px;
    font-family: Roboto;
    font-weight: 600;
    margin-left: 8px;
    margin-right: 8px;
  }

  .title {
    display: none;
    color: var(--primary-500, #2499ef);
    font-size: 16px;
    font-family: Roboto;
    font-weight: 500;
    margin-left: 8px;
  }

  .icon {
    svg {
      font-size: 24px;
      color: #96abc4;
    }
  }
}
