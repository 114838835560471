.projects {
  .list {
    margin-bottom: 20px;

    .item {
      border-radius: 8px;
      border: 1px solid #e9eff6;
      padding: 24px 16px 12px 16px;
      margin-bottom: 10px;

      .select {
        margin-bottom: 4px;
      }

      .remove {
        text-align: right;

        svg {
          color: #96abc4;
        }
      }
    }
  }
}
