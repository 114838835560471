.modal {
  padding: 16px 24px;

  p {
    color: var(--text-text-main, #011e3d);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .name {
      color: var(--primary-500, #2499ef);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
  }
}

.footer {
  padding: 16px 24px;
}
