.button {
  &[data-color='secondary'] {
    background: #f1f8fe;
    color: #2499ef;
    border: none;

    &:hover {
      background: #c6e7ff;
    }
  }

  &[data-color='error'] {
    background: rgba(255, 49, 111, 0.15) !important;
    color: var(--other-error, #ff316f) !important;
    border: none;

    &:hover {
      background: #ff316f !important;
      color: var(--white, #fff) !important;
    }
  }
}
