.auth {
  margin-bottom: 32px;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 140%;
  color: #335474;
  height: 300px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .header {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    color: #011e3d;
    margin-bottom: 8px;
  }

  .content {
    .email {
      margin-bottom: 8px;
    }
  }
}
