.heading {
  display: flex;
  align-items: center;

  &[data-level='1'] {
    font-size: 24px;
    margin-bottom: 40px;
  }

  &[data-level='2'] {
    font-size: 20px;
    margin-bottom: 40px;
  }

  &[data-level='3'] {
    font-size: 18px;
    margin-bottom: 24px;
  }

  .icon {
    flex-grow: 0;
    margin-right: 10px;
    background: #e5f3fd;
    border-radius: 4px;
    padding: 6px;

    svg {
      font-size: 1.2em;
    }
  }

  .title {
    flex-grow: 2;
    font-weight: 500;
  }

  .toolbar {
    flex-grow: 0;
  }
}
