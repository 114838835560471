.input {
  border-radius: 8px;
  border: 1px solid var(--grey-400, #dce5f0);
  padding: 15px 15px 11px 15px;
  min-height: 56px;
  position: relative;
  display: flex;
  align-items: center;

  .tags {
    flex-grow: 2;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .text {
      flex-grow: 2;
      margin-bottom: 4px;

      input {
        height: 24px;
        border: none;
        color: var(--text-text-main, #011e3d);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        outline: none;
        width: 100%;
        cursor: pointer;
      }
    }
  }

  .icon {
    flex-grow: 0;

    svg {
      font-size: 12px;
      color: #335474;
    }
  }
}
