@mixin mediaHeight($large, $medium, $small) {
  height: $small;

  @media (min-height: 700px) {
    height: $medium;
  }

  @media (min-height: 1000px) {
    height: $large;
  }
}
