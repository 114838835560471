.education {
  .add {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    strong {
      color: var(--primary-500, #2499ef);
      font-size: 14px;
      font-weight: 500;
    }

    i {
      position: relative;
      top: 1px;

      svg {
        color: var(--primary-500, #2499ef);
        font-size: 1em;
      }
    }
  }

  .list {
    margin-bottom: 20px;

    .item {
      border-radius: 8px;
      border: 1px solid #e9eff6;
      padding: 24px 16px 12px 16px;
      margin-bottom: 10px;

      .select {
        margin-bottom: 4px;
      }

      .remove {
        text-align: right;

        svg {
          color: #96abc4;
        }
      }
    }
  }
}
