.job {
  border-radius: 8px;
  border: 1px solid #e9eff6;
  padding: 24px 16px;
  margin-bottom: 16px;
  position: relative;

  &[data-delete='delete'] {
    padding-bottom: 40px;

    .footer {
      display: block;
    }
  }

  .header {
    display: flex;
    margin-bottom: 20px;
    cursor: pointer;

    .radio {
      margin-right: 8px;
    }

    .label {
      color: var(--text-text-400, #335474);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .footer {
    display: none;
    position: absolute;
    bottom: 10px;
    right: 20px;

    svg {
      color: #96abc4;
    }
  }
}
