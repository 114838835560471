.item {
  display: flex;
  align-items: center;
  // border: 1px solid #f7f7f7;
  // background: #f7f7f7;
  background: #fff;
  border: 1px solid #eee;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 3px;
  transition: 0.3s all;

  &[data-active='active'] {
    background: #2499ef;
    color: #fff;
    border-color: #2499ef;

    &:hover {
      background: #2499ef;
    }

    .icon {
      svg {
        color: #fff;
      }
    }
  }

  &:hover {
    background: #eee;
  }

  .icon {
    margin-right: 5px;

    svg {
      color: #2499ef;
    }
  }

  .index {
    font-weight: 600;
    font-size: 0.8em;
    opacity: 0.5;
    margin-right: 0.2em;
  }

  .title {
    font-size: 0.8em;
    font-weight: 500;
  }
}
