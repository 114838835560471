.tag {
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  background: var(--primary-500, #2499ef);
  color: #fff;
  padding: 4px 10px;
  cursor: pointer;
  margin-right: 4px;
  margin-bottom: 4px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  transition: 0.3s background;

  &:hover {
    background: #208cdc;
  }

  &[data-inactive='inactive'] {
    background: #8ca3ba;

    &:hover {
      background: #8ca3ba;
    }
  }

  &:active {
    position: relative;
    top: 1px;
  }

  &[data-marginless='marginless'] {
    margin-bottom: 0;
  }

  .button {
    margin-right: 5px;
    cursor: pointer;

    svg {
      font-size: 10px;
    }
  }

  .label {
    color: var(--White, #fff);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
