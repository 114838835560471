.picker {
  .dateInput {
    width: 100%;
    font-family: 'Roboto';
    font-size: 13px;
    color: #738499;
    box-sizing: border-box;
    padding: 5px 3px;
    background: #ffffff;
    // border: 1px solid #dce5f0;
    border-radius: 8px !important;
  }
}
