.events {
  .items {
    display: flex;
    flex-wrap: wrap;
  }

  .selectAll {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    .title {
      font-family: 'Roboto';
      font-size: 12px;
      color: #011e3d;
    }
  }
}
