.resource {
  .tabs {
    position: relative;
    bottom: 5px;
  }

  .search {
    margin-top: 24px;
  }

  .content {
    // margin-top: 16px;
  }
}
