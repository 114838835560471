.button {
  padding: 8px 8px;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #ccddf0;
  border-radius: 8px;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s opacity, 0.3s border-color;

  &:hover {
    opacity: 1;
    border: 1px solid #45abf6;
  }

  &[data-expanded='expanded'] {
    opacity: 1;
    border: 1px solid #45abf6;
  }

  .icon {
    flex-grow: 0;
    position: relative;
    top: 1px;
    margin-right: 5px;

    svg {
      font-size: 16px;
    }
  }

  .title {
    font-family: 'Roboto';
    font-size: 13px;
    color: #738499;
    flex-grow: 2;
    position: relative;
    bottom: 1px;
  }

  .expandedIcon {
    position: relative;
    top: 1px;

    svg {
      font-size: 14px;
    }
  }
}
