.edit {
  .dates {
    .inputs {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .line {
        width: 40px;
        height: 1px;
        background: #dce5f0;
      }
    }

    .footer {
      display: flex;

      .error {
        padding-top: 10px;
        flex-grow: 2;

        section {
          margin-bottom: 20px;
        }
      }

      .now {
        padding-top: 16px;
        flex-grow: 2;
        text-align: right;
      }
    }
  }
}
