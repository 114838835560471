@import '@styles';

.modal {
  display: flex;

  .tabs {
    border-right: 1px solid #ddd;
    flex-grow: 0;
    width: 240px;
  }

  .content {
    padding: 16px;
    overflow-y: auto;
    flex-grow: 2;
    width: 700px;
    position: relative;

    @include mediaHeight(572px, 400px, 300px);
  }
}

.footer {
  border-top: 1px solid #eee;
  padding: 10px;
  height: 57.19px;
}
