*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer,
header,
nav,
section,
main {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

a {
  text-decoration: none;
}

iframe {
  display: none;
}

body {
  font-family: 'Roboto', sans-serif;
  background: #f5f8fa;
}

.datepicker {
  position: relative;
  bottom: 5px;

  .MuiFormLabel-root {
    font-family: 'Roboto' !important;
    font-size: 13px !important;
    color: #738499 !important;
  }

  .MuiInputBase-input {
    width: 100% !important;
    font-family: 'Roboto' !important;
    font-size: 13px !important;
    color: #738499 !important;
    box-sizing: border-box !important;
    padding: 22px 23px 24px 23px !important;
    background: #ffffff !important;
    // border: 1px solid #dce5f0;
    border-radius: 13px !important;

    &::placeholder {
      font-family: 'Roboto' !important;
      font-size: 13px !important;
      color: #738499 !important;
    }
  }

  .MuiInputBase-root {
    border-radius: 8px !important;
  }
}

.MuiDialogTitle-root {
  font-family: 'Roboto', sans-serif !important;
  border-bottom: 1px solid #eee !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #011e3d !important;
}

.MuiDialogContent-root {
  padding-top: 24px !important;
  font-family: 'Roboto', sans-serif !important;

  &.paddingless {
    padding: 0 !important;
  }
}

.wide-dialog {
  .MuiDialog-container {
    .MuiPaper-root {
      max-width: 1200px !important;
      max-height: 70vh;
    }
  }
}

.MuiInputBase-root.Mui-disabled {
  background: #f7f7f7;
}

.MuiInputBase-root {
  font-size: 14px !important;
}

#overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000000;
}

.MuiTabs-flexContainer {
  button {
    font-family: 'Roboto';
    font-weight: 600;
    margin-right: 5px;
    margin-left: 5px;
  }
}

.MuiFormControlLabel-root .MuiTypography-root {
  font-size: 0.9em;
  margin-left: 0.5em;
}

.MuiInputLabel-formControl[data-shrink='false'] {
  top: 3px;
  left: 6px;
}

.MuiSelect-select {
  font-size: 13px !important;
}

.bubble {
  background: #f9f9f9;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 140%;

  b,
  strong {
    font-weight: 500;
  }

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.MuiInputBase-input {
  color: var(--text-text-main, #011e3d) !important;
  font-family: Roboto !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  outline: none !important;

  &[disabled] {
    background: #f1f6f9 !important;
    color: var(--text-text-400, #335474) !important;
    font-family: Roboto !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }
}
