.switch {
  text-align: right;
  display: flex;
  justify-content: flex-end;

  .option {
    cursor: pointer;
    margin-left: 5px;

    &:hover {
      svg {
        color: #45abf6;
      }
    }

    &[data-active='active'] {
      svg {
        color: #45abf6;
      }
    }

    svg {
      color: #96abc4;
    }
  }
}
