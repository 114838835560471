.item {
  display: flex;
  align-items: center;
  padding: 12px 16px 12px 16px;
  cursor: pointer;
  margin-left: -16px;
  transition: 0.3s background;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  &[data-padded='padded'] {
    margin-left: 0;
  }

  &[data-color='color'] {
    .checkbox {
      margin-right: 8px;
    }
  }

  &:hover {
    background: var(--grey-300, #e9eff6);

    .title {
      color: var(--primary-500, #2499ef);
    }
  }

  .checkbox {
    margin-right: 16px;
  }

  .color {
    margin-right: 3px;

    .circle {
      width: 8px !important;
      height: 8px !important;
      border-radius: 50%;
    }
  }

  .title {
    color: var(--text-text-main, #011e3d);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: 0.3s color;
  }
}
